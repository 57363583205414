// extracted by mini-css-extract-plugin
export var CardWrapper = "Tkid-module--CardWrapper--pKagv";
export var CarrouselWrapper = "Tkid-module--CarrouselWrapper--+ayK0";
export var DescriptionWrapper = "Tkid-module--DescriptionWrapper--eyT0x";
export var ExpoWrapper = "Tkid-module--ExpoWrapper--pYK2M";
export var NameWrapper = "Tkid-module--NameWrapper--beFKa";
export var PdpWrapper = "Tkid-module--PdpWrapper--FctZ9";
export var PhotosWrapper = "Tkid-module--PhotosWrapper--5SLdW";
export var ProfilWrapper = "Tkid-module--ProfilWrapper--NHu7x";
export var VideoWrapper = "Tkid-module--VideoWrapper--JbIc4";
export var VideosWrapper = "Tkid-module--VideosWrapper--IvXBl";
export var WorkWrapper = "Tkid-module--WorkWrapper--M3RuJ";
export var WorksWrapper = "Tkid-module--WorksWrapper--w4vQN";
export var Wrapper = "Tkid-module--Wrapper--+U0rJ";