import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CarrouselWrapper,
CarrouselWrapper2,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
} from "./Tkid.module.css";
import Title from "../../../../components/Title";
import Carrousel from "../../../../components/Carrousel";
import TKidPhoto1 from "../../../../res/Photos site/T-Kid/Style-Rubban-ghost-yard-train.jpeg"
import TKidPhoto2 from "../../../../res/Photos site/T-Kid/T-kid book.jpg"
import TKidPhoto3 from "../../../../res/Photos site/T-Kid/t-kid-.jpg"

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "TKid",
  name: "TKid",
  description:"",
  pdp: TKidPhoto2,
  alt_pdp: "Photo de profil de TKid.",
  photos: [
    { src: TKidPhoto1, name: "TKid" },
    { src: TKidPhoto3, name: "TKid" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
};

const TKid = () => {
    return (
        <div className={Wrapper}>
        <div className={CardWrapper}>
          <div className={ProfilWrapper} id="f">
            <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
          </div>
          <div>
            <h1 className={NameWrapper}>T-Kid</h1>
            <h3 className={NameWrapper} style={{paddingTop: '0'}}></h3>
            <p className={DescriptionWrapper}>{infos.description}</p>
          </div>
        </div>
        <div className={PhotosWrapper}>
          <Title title={"Past or Present"} />
          <div className={CarrouselWrapper2}>
            <Carrousel images={infos.photos} />
          </div>
        </div>
      </div>
      );
};

export default TKid;